/***
*
*   SETUP FINAL SCREEN
*   You can delete this when you've completed the setup process.
*
**********/

import React, { Fragment } from 'react';
import { Message } from 'components/lib';

export function SetupFinished(props){

  return(
    <Fragment>

      <Message
        type='success'
        title='Setup Complete'
        text={ 'You can now create a user account and start building your application. Please remove all setup files now for security.' }
       />

    </Fragment>
  );
}
