/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import React from 'react';
import { Animate, Content, Link, Image } from 'components/lib';
import Style from './hero.module.scss';

export function Hero(props){

  return (
    <section className={ Style.hero }>
      <Content>

        <Animate type='pop'>
          <section className={ Style.blurb }>
            <h1>{ props.title }</h1>
            <h2>{ props.tagline }</h2>
            <Link btn big url='/signup' text='Sign Up Now' />
          </section>
        </Animate>

        <Animate>
          <Image className={ Style.visual } source={ props.image } alt={ props.alt } />
        </Animate>

      </Content>
    </section>
  )
}
